import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss'
import KaustIcon from '../assets/images/logos/kaust.png'
import DelongIcon from '../assets/images/logos/delong.svg'
import AdbrosIcon from '../assets/images/logos/adbros.png'
import CaverIcon from '../assets/images/logos/caver.png'

import { Grid } from "@mui/material";

const employments = [
  {
    date: "04/2023 - present",
    position: "Research Scientist",
    place: "King Abdullah University of Science and Technology (KAUST), KSA",
    description: "",
    link: "www.vccvisualization.org",
    icon: KaustIcon
  },
  {
    date: "11/2019 – 03/2023",
    position: "Research Scientist",
    place: "King Abdullah University of Science and Technology (KAUST), KSA",
    description: "",
    link: "www.nanovis.org",
    icon: KaustIcon
  },
  {
    date: "05/2014 – 10/2019",
    position: "Senior Software Developer",
    place: "DELONG INSTRUMENTS a.s., Brno, Czech Republic",
    description: "",
    link: "www.delong.cz",
    icon: DelongIcon
  },
  {
    date: "02/2019 – 05/2019",
    position: "Visiting Researcher",
    place: "King Abdullah University of Science and Technology (KAUST), KSA",
    description: "",
    link: "www.kaust.edu.sa",
    icon: KaustIcon
  },
  {
    date: "03/2007 – 05/2014",
    position: "Software Developer",
    place: "DELONG INSTRUMENTS a.s., Brno, Czech Republic",
    description: "",
    link: "www.delong.cz",
    icon: DelongIcon
  },
  {
    date: "06/2016 – 08/2018",
    position: "Backend Developer",
    place: "AdBros s.r.o., Brno, Czech Republic",
    description: "",
    link: "www.adbros.cz",
    icon: AdbrosIcon
  },
  {
    date: "08/2011 – 09/2015",
    position: "Researcher, Software Developer, co-founder",
    place: "CaverSoft s.r.o, Brno, Czech Republic",
    description: "",
    link: "www.caver.cz",
    icon: CaverIcon
  }
]


function Timeline() {
  return (
    <div id="history">
      <div className="items-container">
        <h1>Career History</h1>

        <VerticalTimeline>
          {
            employments.map(e =>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'white', color: 'rgb(39, 40, 34)' }}
                contentArrowStyle={{ borderRight: '7px solid  white' }}
                date={e.date}
                iconStyle={{ background: '#1E90FF', color: 'rgb(39, 40, 34)' }}
                icon={<FontAwesomeIcon icon={faBriefcase} />}
              >
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <h3 className="vertical-timeline-element-title">{e.position}</h3>
                    <h4 className="vertical-timeline-element-subtitle">{e.place}</h4>
                  </Grid>
                  <Grid item xs={3}>
                    <img src={e.icon} width={60} />
                  </Grid>
                  <Grid item xs={12}>
                    <p>{e.description}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <p><a href={e.link} target="_blank">{e.link}</a></p>
                  </Grid>
                </Grid>

              </VerticalTimelineElement>
            )
          }
        </VerticalTimeline>

      </div>
    </div>
  );
}

export default Timeline;