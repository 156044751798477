import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faDocker, faWindows } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';


const desktop = [
    "C#",
    "C/C++",
    "Qt",
    "WinForms",
    "WPF",
    "WCF",
    "Windows",
    "Windows Service",
    "OpenGL",
    "Vulkan",
    "GLSL",
    "Java",
    "NetBeans",
    "OpenVR/OpenXR",
    "HoloLens"
];

const fullstackWeb = [
    "React",
    "TypeScript",
    "JavaScript",
    "HTML5",
    "CSS3",
    "SASS",    
    "Python",
    "SQL",
    "MongoDB",
    "PHP",
    "WebGPU",
    "WebAssembly",
    "Node.js",
    "GraphQL"
];

const labelsSecond = [
    "Git",
    "GitHub Actions",
    "Linux",
    "Render.com"    
];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Expertise</h1>
            <div className="skills-grid">
                <div className="skill">
                    <FontAwesomeIcon icon={faWindows} size="3x"/>
                    <h3>Desktop Applications Development</h3>
                    <p>I have extensive professional experience developing enterprise-grade, content-rich desktop systems, as well as systems that interface with hardware devices. Additionally, I have worked with various graphics APIs. More recently, I have begun integrating AI models into desktop applications.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {desktop.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faReact} size="3x"/>
                    <h3>Full Stack Web Development</h3>
                    <p>Throughout my career, I have developed numerous commercial web applications from the ground up, utilizing technologies like PHP, JavaScript, and ReactJS. I have also specialized in graphics development for web browsers, working with technologies such as WebGL and WebGPU.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {fullstackWeb.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faDocker} size="3x"/>
                    <h3>DevOps & Automation</h3>
                    <p>I have designed and implemented robust CI/CD pipelines using GitHub Actions and GitLab CI, streamlining and automating the deployment process to ensure efficient and reliable software delivery.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsSecond.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                
            </div>
        </div>
    </div>
    );
}

export default Expertise;